export const environment = {
  // --------Fixed-----------------------//
  production: true,
  companyName: 'CustomBooks™',
  companyURL: 'https://www.custombooks.com',
  supportURL: 'https://support.custombooks.com',
  supportPortalURL: 'https://help.custombooks.com/portal/home',
  blogURL: 'https://www.custombooks.com/blog/',
  currency: 'USD',
  helpLineNumber: '888.328.8275',
  inquireNumber: '415.462.5477',
  acsFranchiseeCode: '000',
  COMMON_ERROR_MESSAGE: 'Sorry something went wrong. Please try again later. ',

  whyAccountingSuiteLink: 'https://www.custombooks.com/why-accounting-suite',

  businessLink: 'https://www.custombooks.com/bookkeeping-webinars',
  overviewLink: 'https://www.custombooks.com/features/',
  findAnAdvisorLink: 'https://www.custombooks.com/acs-rockstars',
  accountingReportingLink: 'https://www.custombooks.com/accounting-reporting',
  salesLink: 'https://www.custombooks.com/sales',
  purchasingLink: 'https://www.custombooks.com/purchasing',
  projectAndTimeTrackingLink: 'https://www.custombooks.com/project-and-time-tracking',
  inventoryManagementLink: 'https://www.custombooks.com/inventory-management',

  partnersLink: 'https://www.custombooks.com/accounting-webinars/',
  accountantAndBookkeeperLink: 'https://www.custombooks.com/for-partners',
  webinarsLink: 'https://www.custombooks.com/accounting-webinars',
  integrationsLink: 'https://www.custombooks.com/integrations',

  getStartedLink: 'https://www.custombooks.com/free-trial-sign-up',
  userGuideLink: 'https://userguide.custombooks.com/user-guide',
  accountingWebinarsLink: 'https://www.custombooks.com/webinars_category/accounting-webinars',
  bookkeepingWebinarsLink: 'https://www.custombooks.com/webinars_category/bookkeeping-webinars',
  productEnhancementsLink: 'https://www.custombooks.com/product-enhancements',
  accountingSuiteAcademyLink: 'https://userguide.custombooks.com',
  accountingSuiteStatusLink: 'http://status.custombooks.com',
  aboutContactLink: 'https://www.custombooks.com/about-contact/',
  pricingLink: 'https://www.custombooks.com/pricing',

  linkedInURL: 'https://www.linkedin.com/company/custombookserp',
  youtubeURL: 'https://www.youtube.com/@CustomBooksERP',
  twitterURL: 'https://x.com/custombookserp',
  facebookURL: 'https://www.facebook.com/CustomBooksERP',
  termOfServiceURL: '',
  privacyPolicyURL: '',
  // --------Fixed-----------------------//

  // --------Change based on environment-----------------------//
  LOGIN_URL: 'https://dev.custombooks.com',
  PAY_URL: 'https://paydev.custombooks.com',
  INTCBK_URL: 'https://intcbkdev.custombooks.com',
  CAT_URL: 'https://catdev.custombooks.com',
  ADD_CARD_URL: 'https://addcarddev.custombooks.com',
  REGISTER_URL: 'https://registerdev.custombooks.com',
  ROCKSTAR_URL: 'https://rockstardev.custombooks.com',
  OAUTH_URL: 'https://oauthdev.custombooks.com',
  DEVELOPER_URL: 'https://apidev.custombooks.com',
  APP_DEMO_URL: 'https://appdemodev.custombooks.com',

  ACS_LOGO_IMAGE: 'logo-dev.png',
  APPLICATION_DETAILS: {
    NAME: 'TangleSolutions Inc.',
    CLIENT_ID: 'x1qon01ia4qbk3yjrh9b',
    CLIENT_SECRET_KEY: '2bxavy8ybe7po49l8uxc',
    STATE: '123456',
    SOURCE: 'AppUser'
  },
  O_DATA_URL: 'https://dev1.custombooks.com/a/ACS_DEV',

  ONEC_SERVICE_USER: 'APIManager',
  ONEC_SECRET_KEY: '123'
  // --------Change based on environment-----------------------//
};
